/* utilities */
/* .mh-inherit { max-height: inherit; } */
body {
    overflow-y: hidden;
}
.overflow-y-auto { overflow-y: auto; }

.pointer { cursor: pointer; }
.bg-success {
    background-color: #0a8d6e !important;
    color: #ffffff !important;
}
.pr-cart{
    padding-right: 1.25rem;
}
.pos--dropdown-list-edit {
    position: absolute;
    list-style: none;
    margin-bottom: 0;
    margin-top: .25rem;
    width: 12rem ;
    padding: .5rem 0;
    padding-bottom: 0 !important;
    border-radius: .5rem;
    background-color: #ffffff;
    overflow-y: auto;
    max-height: 22rem;
    z-index: 2;
    box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
    transition: opacity 0.2s linear;
}

.image-btn{
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f0f0f0;
    margin-right: 5px;
}

/* .added{
    -webkit-animation-name: shakeCart;
    -webkit-animation-duration: 0.8s;
    -webkit-animation-direction: alternate;
    -webkit-animation-timing-function: cubic-bezier(
    .5, 0.05, 1, .5);
    -webkit-animation-iteration-count: infinite;
}
  
@keyframes shakeCart{
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    to {
        -webkit-transform: translate3d(0, 30px, 0);
        transform: translate3d(0, 30px, 0);
    }
} */

.stockQty{
    border-radius: 5%;
    color: rgb(255, 255, 255);
    top: 0px;
    right: 1px;
    z-index: 99;
    font-size: 0.8rem;
    font-weight: 500;
    padding: 0.1rem 0.25rem;
}

.stock-bg{
    background-color: #0a8d6e;
}

.stock-bg-danger{
    background-color: rgb(226, 15, 15);
}

.disabled{
    opacity: 0.8;
    pointer-events: none;
}

.category-box {
    height: calc( 100vh - 102px );
}
.menu-left {
    height: calc( 100vh - 190px );
}
.menu-left .simplebar-content{
  margin-right: -2.65rem !important;
} 
.bill-right {
    padding-left: 1.1rem;
    height: calc( 100vh - 207px );
}
.items-wrap {
    height: calc(100vh - 430px);
    overflow-y: scroll;
    overflow-x: hidden;
    transition: opacity 0.2s linear;
}

.customer-screen-wrap{
    height: calc(100vh - 433px);
    overflow-y: scroll;
    overflow-x: hidden;
    transition: opacity 0.2s linear;
}

.form-control-card{
    margin-right: 18px;
    width: 70%;
    min-height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.3rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control-card::placeholder{
    font-size: 1rem;
    color: #6c757d;
    opacity: 1;
}

/* ========== Switcher ============== */
.indolj-switcher-container {
    display: flex;
    background-color: rgb(0 0 0 / 14%);
    border-radius: 5rem;
    width: max-content;
    margin: 0 auto;
    position: relative;
}
.indolj-switcher-container .switch {
    font-size: 0.6rem;
    padding: 0.25rem 0.55rem;
    cursor: pointer;
    user-select: none;
    position: relative;
    text-transform: uppercase !important;
    font-weight: 600;
    z-index: 1;
    transition: all 0.5s linear;
}

.indolj-switcher-container .switch.active-switch{
    color: white;
}
.indolj-switcher-container .driveActive{
    width: var(--delivery-switcher-width);
    /* height: var(--delivery-switcher-height); */
    left: var(--delivery-switcher-left);
    background-color: #0a8d6e;
    /* height: 1.9rem; */
    position: absolute;
    border-radius: 5rem;
    top: 0px;
    bottom: 0px;
    transition: all 0.30s linear;
}
.pos--btn .special-category{
    animation: blinkingBackground 2s alternate infinite;
}
.dropdwn--btn .special-category{
    animation: blinkingBackground 2s alternate infinite;
}
.foodPanda-btn .special-category{
    animation: blinkingFpBackground 2s alternate infinite;
}
@keyframes blinkingFpBackground {
    0%	{ 
        /* background-color: green; */
        color: rgb(255, 255, 255);
    }
    50%	{ 
                /* background-color: white; */
        color: #f796bea9;
    }
    100% { 
                /* background-color: rgb(16, 32, 16); */
        color: rgb(247, 247, 247);
    }
}
@keyframes blinkingBackground {
    0%	{ 
        /* background-color: green; */
        color: rgb(255, 255, 255);
    }
    50%	{ 
                /* background-color: white; */
        color: #0dd19d;
    }
    100% { 
                /* background-color: rgb(16, 32, 16); */
        color: rgb(247, 247, 247);
    }
}

.cart-item-wrapper.delBlink{
    animation: delblinker 0.3s ease-in-out;
}
@keyframes delblinker {
    0% {
        background-color: transparent   ;  
    }
    25% {
        background-color: #fc202057;
    }
    50% {
        background-color: #db2626;
    }
    75% {
        background-color: #c42a2a65;
    }
    100% {
        background-color: transparent;
    }
}

.cart-item-wrapper.blink {
    animation: blinker 0.3s ease-in-out;
}
@keyframes blinker {
    0% {
        background-color: transparent   ;  
    }
    25% {
        background-color: #0a8d6e3a;
    }
    50% {
        background-color: #0a8d6e;
    }
    75% {
        background-color: #0a8d6e5e;
    }
    100% {
        background-color: transparent;
    }
}

.btn-back {
/* margin-right: 40px; */
/* font-size: 1rem; */

}

.lock_icon{
    font-size: 1.35rem !important;
}

.foodPanda-btn{
    background-color: #D70F64;
    border-radius: .5rem !important;
    padding: 0.3rem !important;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.8rem;
    line-height: 2;
}

.editOrderPos{
    border: 1px solid #7494a4;
    border-radius: 0.4rem;
    line-height: 1.05;
    cursor: pointer;
}
.editOrderPos:hover {
    background-color: #27ccaa;
    
}
.editOrderPos:hover .editDetail {  
    color: white;
}
.editOrderPos:hover .editOrderBranch {  
    color: white;
}
.editOrderPos:hover .editIcon span i{  
    color: white !important;
}
.editOrderPos:hover .editPrice {  
    background-color: white;
    color: #27ccaa;
}
.editDetail{
    color: black;
    font-weight: 500;
}

.editPrice{
    display: flex;
    align-items: center;
    background-color: #27ccaa;
    color: white;
    border-radius: 0.25rem;
    padding: 0.2rem 0.4rem;
    margin-right: 0.5rem;
}

.hold-text{
    font-size: 0.83rem;
    font-weight: 500;
}

.editOrderBranch{
    height: auto;
    color: #6c757d;
    font-weight: 500;
}
.editIcon{
    width: 20%;
    height: 100%;
    float: right;
    display: flex;
    justify-content: flex-end;
}
.editIcon span i{
    font-size: 0.9rem !important;
}

.items-wrap::-webkit-scrollbar, .customer-screen-wrap::-webkit-scrollbar{
    background-color: transparent;
    width: 0.4rem;
    
}

.item_comment_icon{
    font-size: 0.85rem;
    border: none;
    border-radius: 0.8rem;
    margin-left: 0.4rem;
    cursor: pointer;

}

.item_delete_icon{
    font-size: 1.1rem;
    border: none;
    color: red;
    padding: 0;
    margin-left: 0.75rem;
    margin-right: 0.25rem;
    cursor: pointer;
    background: transparent;
}

.item_edit_icon{
    font-size:1.1rem;
    border: none;
    padding: 0;
    background: transparent;
    margin-left: 0.75rem;
    cursor: pointer;
}
.edit_icon{
    fill: white;
    width: 1rem;
    height: 1rem;
    margin-right: 0.3rem;
}
.editOrderPos:hover .edit_icon{
    fill: #0a8d6e;
}

/*modal scroll */ 
.item-scroll{
    overflow: auto;
    overflow-x: hidden;
    transition: opacity 0.2s linear;
}
.item-scroll::-webkit-scrollbar {
    background-color: transparent;
    width: 0.5rem;
}
.item-scroll::-webkit-scrollbar-track {
   background-color: transparent;
   width: 0.5rem;
}

.item-scroll::-webkit-scrollbar-thumb{
    visibility: hidden;
    background-color: rgba(44, 44, 44, 0.24);
    border-radius: 1rem;
    width: 0.5rem;
 }
 .item-scroll:hover::-webkit-scrollbar-thumb,
 .item-scroll:active::-webkit-scrollbar-thumb,
 .item-scroll:focus::-webkit-scrollbar-thumb
  {
    background-color: rgba(44, 44, 44, 0.24);
    border-radius: 1rem;
    visibility: visible;
  }

.add-container {
    margin-left: 1.5px;
    background-color: #fdfdfd;
    box-shadow: 0px 1px 2px 1px rgba(53, 52, 52, 0.24);
    margin-bottom: 0.45rem;
    border-radius: 5px;
    padding: 3.5px 0;
    width: 98%;
}
.add-container > input[type="radio"] {
    margin-left: 5px;
    display: block;
    accent-color: #0a8d6e !important;
    width: 1.2rem;
    height: 1.2rem;
}

.add-container > span{
  font-size: 1rem;
  color: #403e48;
}

.adress-select{
  background-color: #ebebeb;
  padding: 2px 0.5rem;
  margin-left: 0.75rem;
  border-radius: 3px;
  margin-bottom: 0.35rem;
}

.adres-icon{
    margin-right: 0.35rem;
    color: #0a8d6e;
}
.modal-left-col{
    box-shadow: 0 3px 0.8rem rgb(0 0 0 / 10%) !important;;
}
.modal-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(219, 219, 219);
}
    .modal-item-name{
        padding: 0.55rem 0.25rem;
        font-size: 1.4rem;
        font-family: var(--bs-font-sans-serif);
        font-weight: 600;
    }
    .items-wrap::-webkit-scrollbar-track, .customer-screen-wrap::-webkit-scrollbar-track {
        background-color: transparent;
    }

  .items-wrap::-webkit-scrollbar-thumb, .customer-screen-wrap::-webkit-scrollbar-thumb{
    visibility: hidden;
    background-color:  rgba(20, 20, 20, 0.3);
    border-radius: 1rem;
  }
  .items-wrap:hover::-webkit-scrollbar-thumb,
  .items-wrap:active::-webkit-scrollbar-thumb,
  .items-wrap:focus::-webkit-scrollbar-thumb,
  .customer-screen-wrap:hover::-webkit-scrollbar-thumb,
  .customer-screen-wrap:active::-webkit-scrollbar-thumb,
  .customer-screen-wrap:focus::-webkit-scrollbar-thumb
   {
    background-color:  rgba(20, 20, 20, 0.3);
    border-radius: 1rem;
    visibility: visible;
   }
.bg2-success {
    background-color: #27ccaa !important;
    color: #ffffff;
}

.mh-inherit {
    background-color: #f1f1f1;
}

.bg3-success {
    border-radius: 50px;
    background-color: #0a8d6e;
    color: #ffffff;
}

.search {
    float: right;
    margin-right: 0.75rem;
    margin-top: -2.1rem;
    z-index: 2;
    color: #0a8d6e;
}
.pos--left-inner-addon .form-control{
    font-size: 0.9rem;
}
.pos--left-inner-addon {
    max-height: 3rem;
    padding-bottom: .3rem;
}

.pos--left-inner-addon i {
    position: relative!important;
}

.offer-tip .tool-tip{
    display: none !important;
    top: 30px;
    left: 20%;
    position: absolute;
    z-index: 999;
    display: block;
    font-size: 0.65rem;
    font-weight: 400;
    opacity: 100;
    background-color: #0a8d6e;
    color: white;
    border-radius: 0.25rem;
    padding: 0.15rem 0.25rem;
  }

  .offer-tip .disable-tip{
    display: none !important;
  }

  .offer-tip:hover > .tool-tip{
    display: inline-block !important;
    opacity: 100 !important;
    background-color: #0a8d6e !important;
    color: white !important;
  }

  .offer-tip .tool-tip::after{
    content: "";
    position: absolute;
    top: -5px;
    left: 50%;
    margin-left: -5px;
    border-top: 0 ;
    border-left: 5px solid #0a8d6e00;
    border-right: 5px solid #0a8d6e00;
    border-bottom: 5px solid #0a8d6e;
    
  }

  .call_centerModal{
    overflow: auto;
    height: 100px;
  }
  .call_centerModal::-webkit-scrollbar {
    background-color: transparent;
    width: 0.35rem;
  }
  .call_centerModal::-webkit-scrollbar-track {
        background-color: transparent;
        width: 0.35rem;
   }

  .call_centerModal::-webkit-scrollbar-thumb{
      visibility: hidden;
      background-color:  rgba(20, 20, 20, 0.3);
      border-radius: 1rem;
  }
  .call_centerModal:hover::-webkit-scrollbar-thumb,
  .call_centerModal:active::-webkit-scrollbar-thumb,
  .call_centerModal:focus::-webkit-scrollbar-thumb
   {
    background-color: rgba(20, 20, 20, 0.3);
    border-radius: 1rem;
    visibility: visible;
    
   }
  
  .taxSelect{
    position: absolute;
    top: 1rem;
    left: 0;
    min-width: 7.5rem;
    width: 100%;
    padding: 0.6rem;
    background-color: #f5f5f5;
    overflow-y: auto;
    max-height: 11rem;
    border-radius: 4px;
  }
  .taxSelect::-webkit-scrollbar {
    background-color: transparent;
    width: 0.35rem;
}
.taxSelect::-webkit-scrollbar-track {
    background-color: transparent;
    width: 0.35rem;
}

  .taxSelect::-webkit-scrollbar-thumb{
      visibility: hidden;
      background-color:  rgba(20, 20, 20, 0.3);
      border-radius: 1rem;
  }
  .taxSelect:hover::-webkit-scrollbar-thumb,
  .taxSelect:active::-webkit-scrollbar-thumb,
  .taxSelect:focus::-webkit-scrollbar-thumb
   {
    background-color: rgba(20, 20, 20, 0.3);
    border-radius: 1rem;
    visibility: visible;
    
   }

.foodPandabtn{
    height: 1.3rem;
    width: 1.3rem;
    margin-right: 0.1rem;
    fill: white;
    color: white;
}

.btn-info {
    
   background-color:  #379de1;
}

.btn-success2 {
    background-color: #7494a4 !important;
    
    color: #ffffff;
}

.btn-success3 {
    background-color: #27ccaa !important;
    
    color: #ffffff;
}
.hover-effect:hover{
  color: rgba(31, 31, 31, 0.9);
}
.btn-down {
    width: 1000%;
    border-radius: 4px;
    font-size: 1rem;
}


.btn-select {
    font-size: 0.8rem;
    border-radius: 20px;
    height: 24px;
    line-height: 0.8;
    padding-top: 2px;
}

.customer-header{
    padding: 1rem !important;
    border-bottom: 1px solid #dee2e6;
}
.customer-title{
    margin-bottom: 0 !important;
}
.customer-input::placeholder{
    font-size: 1rem;
}

.modal-title {
    margin-top: -1px;
}

.modal-footer {
    margin-top: 0px;
    justify-content: center;
    margin-left: 0px;
}

.class-normal {
    margin-right: 150px;
}

.class-variants {
    font-weight: bold;

}


.input-field {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.bg-danger {
    background-color: #228B22 !important;
    color: #ffffff;
}

.table-2 {
   margin-right: -80px;
}

.table-header {
    margin-top: -4px;
    font-weight: bold;
}

/*table stlying*/
.table-heading {
    text-align: center;
    margin-top: 80px;
}

.merge-table-lf{
    padding: 5px;
    cursor: pointer;
    position: relative;
    border-radius: 0.5rem 0 0 0.5rem !important ;
}
.merge-table-lr{
    padding: 5px;
    cursor: pointer;
    position: relative;
    border-radius: 0 0.5rem 0.5rem 0;
}

.modal--table-item {
    border-radius: 0.5rem;
    padding: 5px;
    cursor: pointer;
    position: relative;
}
.tableName{
    position: absolute;
    /* top:50%;
    left: 50%;
    transform: translate(50%,50%); */
    color: white;
    font-size: 0.7rem;
    font-weight: 700;
}
.table-reserve{
    background-color: #c7c4c4cc;
    opacity: 0.8;
}

.modal--table-item .table-tooltip-none{
  display: none;
}

.modal--table-item .table-tooltip{
    visibility: hidden;
    width: 90px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    font-size: 0.65rem;
    top: 3px;
    /* Position the tooltip */
    position: absolute;
    z-index: 999;
}

.modal--table-item:hover .table-tooltip {
    visibility: visible;
  }

  .reservation_time{
    position: absolute;
    font-weight: 500;
    padding: 1px;
    font-size: 0.7rem;
    bottom: 0px;
  }

.disable_table{
    /* pointer-events: none; */
    cursor: not-allowed;
}
.selected-table .tableName{
    color: #0a8d6e !important;
}
.res-table{
    height: calc(100vh - 18rem) ;
    align-content: baseline;
    overflow-y: auto;
}
.res-table::-webkit-scrollbar {
    background-color: transparent;
    width: 0.5rem;
}

  .res-table::-webkit-scrollbar-thumb{
      visibility: hidden;
      background-color:  rgba(20, 20, 20, 0.3);
      border-radius: 1rem;
  }
  .res-table:hover::-webkit-scrollbar-thumb,
  .res-table:active::-webkit-scrollbar-thumb,
  .res-table:focus::-webkit-scrollbar-thumb
   {
    background-color: rgba(20, 20, 20, 0.3);
    border-radius: 1rem;
    visibility: visible;
    
   }

.split{
    position: relative;
    width: 100%;
}
.selected-table .halfTable{
    color: #0a8d6e ;
}
.halfTable{
  position: absolute;
  top: 50%;
  width: 68%;
  padding: 0.15rem;
  color: white;
  font-weight: 500;
  white-space: pre-line;
  transform: translate(0%,-50%);
  font-size: 0.6rem !important;
}
.halfTableLeft{
    right: 0;
}
.halfTableRight{
    left: 0rem;
}
.selected-table{
    background-color: #0a8d6e !important;
    color: white;
}


.selected-table #Subtraction_1{
    color: white;
    fill: white;
}

.selected-table [id^="Path_"] {
    color: white;
    fill: white;
}

/* pos */
/* .pos--main-container {
    height: 100% !important;
    max-height: calc(100vh - 110px) !important;
} */
/* .pos--main-container > div {
    max-height: inherit !important;
} */
/* .pos--main-content-wrapper {
    max-height: calc(100% - 100px);
    flex-wrap: nowrap;
} */

.pos--main-content-wrapper  {
    /* cursor: pointer; */
}


.dropdwn--btn{
    padding: 0.3rem !important;
    border-radius: .5rem 0 0 .5rem !important;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.8rem;
    line-height: 2; 
    margin-right: 1px;
}
.dropdwn1--btn{
    padding: 0.3rem 0.35rem !important;
    border-radius: 0 .5rem .5rem 0 !important;
    font-weight: 600;
    border-left: 0.1rem solid #ffffff8e !important;
    text-transform: uppercase;
    font-size: 0.8rem;
    line-height: 2; 
}
.dropdwn1--btn > i{
    margin-left: 0 !important;
}
/* button rounded*/
.rounded-05 { border-radius: .5rem !important;}
.rounded-25 { border-radius: .30rem !important;}
.pos--btn {
    padding: 0.3rem !important;
    border-radius: .5rem !important;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.8rem;
    line-height: 2; 
    
}

.pos--icon-btn {
    padding-top: 0.3rem !important;
    padding-bottom: 0.3rem !important;
    line-height: 1;
    font-size: 1.8rem;
}

    
.pos--btn2 {
        border: radius 0.8rem; 
        margin-top: -30px;
}

.btn-table{
    border-radius: 0.5rem;
    line-height: 1;
    margin-left: 0;
    font-size: 1rem;
    margin-right: 2px;
    padding: 0.5rem 1rem;
}
.btn-table:hover{
  color: #0a8d6e;
}
.floorActive .table-floor{
    border-bottom: 0.13rem solid #0a8d6e;
}
.table-floor{
    font-size: 0.9rem;
    font-weight: 500;
    margin: 3px 0; 
}
.floorTable{
    cursor: pointer;
    color: #403e48;
}
.customer-input::placeholder{
    font-size: 1rem;
    font-weight: 400;
}
/* .floorTable:hover{
    color: #27ccaa;
} */
.floorActive{
    /* border-bottom: 2px solid #0a8d6e; */
    color: #0a8d6e;
}


.pos--category-nav-item {
    font-size: 1rem;
    padding: 0.25rem 0.5rem !important;
    justify-content: space-between;
    cursor: pointer;
    color: #403e48;
    margin: 0.15rem 0.5rem;
    margin-right: 0.26rem !important;
    transition: 0.5s linear ease-in-out ;
    border-radius: 0 !important;
    border: .2rem solid hsla(0,0%,100%,0) !important ;
    border-bottom: 0.1rem solid #888888b2 !important;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.pos--category-nav-item:hover{
    background-color: #0a8d6e;
    border-radius: 0.4rem !important;
    color: white;
}

.cartInput{
    font-size: 0.7rem !important;
    height: 0.7rem !important;
}
.cartInput::placeholder{
    font-size: 0.7rem !important;
}

.cartInputQty{
    font-size: 0.7rem !important;
    height: 0.7rem !important;
}
.cartInputQty::placeholder{
    font-size: 0.7rem !important;
}

.cartInputQty:hover + .qty_suggestion {
    display: flex;
  }
.qty_suggestion{
    display: none;
    position: absolute;
    left: -1%;
    right: -1%;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    z-index: 9;
    padding: 0.2rem 0;
    padding-bottom: 0;
    border-radius: 0.2rem;
    background-color: rgb(248, 248, 248);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.qty_suggestion > div {
    cursor: pointer;
    background-color: rgb(170, 170, 170);
    color: white;
    padding: 0.15rem;
    border-radius: 0.15rem;
}
.qty_suggestion:hover{
    display: flex;
}
.qty_suggestion .active {
    background-color: #0a8d6e;
    color: white;
}

.pos--category-nav-item.active {
   /* background-color: #98e8c1; */
    color: #0a8d6e;
    border: 0.20rem solid #0a8d6e !important;
    font-weight: 500;
    border-radius: 0.4rem !important;
}
.pos--category-nav-item.active:hover{
    background-color: #0a8d6e;
    color: white;
}
/* .pos--category-nav-item.active::after {
    content: "\f061";
    font-family: "Font Awesome 5 Free";
} */


.pos--row {
    flex-wrap: wrap;
    position: relative;
    z-index: 1045; 
}
 
 .pos--left-inner-addon i {
    position: absolute;
    justify-content: flex-end;
    max-width: 200%;
    pointer-events: none;
}  

.pos--bill-container {
    /* margin-top: px; */
    background-color: #fff;
    /* min-height: 70vh !important; */
}
/* .fk-scroll--pos-menu{
    height: calc(100vh - 200px);
} */

.pos--menu-item {
    flex: 0 0 auto;
    width: calc(25% - .5rem);
    display: flex;
    justify-content: center;
    color: #403e48;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.1s;
    padding: 0 !important;
     /* transition: background-color 0.5s ease; */
     box-shadow: 1px 1px 5px 1px rgb(36 35 35 / 25%);
     -webkit-box-shadow:1px 1px 5px 1px rgb(36 35 35 / 25%);
     -moz-box-shadow: 1px 1px 5px 1px rgb(36 35 35 / 25%);
   
}

.item-img{
    width: 100%;
    border-radius: 0.5rem 0.5rem 0 0;
    height: 5.2rem;
    object-fit: cover;
}
.item-name{
    font-size: 0.8rem;
    margin-bottom: -0.6rem;
    margin-top: 0.5rem;
}
.bill-list div{
    font-weight: 400 !important;
    font-size: 0.80rem !important;
}


.bill-list{
    /* margin-top: -14px; */
    padding: 0.2rem 0 !important;
}




.bill-top {
font-size: 0.80rem;
}

.react-datepicker{
    display: flex;
}


.total-bill {
    font-size: 0.8rem;
}


.pos--menu-item:hover {
    background-color: #0a8d6e;
    /* margin-top: 15px !important; */
    color: #ffffff;
    transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition-duration: 0.15s;
    
    
}


.pos--menu-variations {
    height: 100%;
    width: calc(100% - 1rem);
}
.pos--menu-variations .btn {
    font-size: 0.8rem;
    line-height: 1;
}
.pos--menu-variations table {
    width: 100%;
}
.pos--menu-variations td {
    width: 50%;
    font-size: 0.7rem;
    border-width: 1px;
    line-height: 1;
    vertical-align: middle;
}

.pos--flex-container {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.pos--btn.bg2-success.selected {
    background-color: #27ccaa !important;
    color: #27ccaa;
    outline: 1px solid #27ccaa;
}


.print-btn {
    margin-left: -80px;
    

}

.pos--dropdown-list {
    position: absolute;
    list-style: none;
    margin-bottom: 0;
    margin-top: .25rem;
    width: 8.2rem ;
    padding: .5rem 0;
    border-radius: .5rem;
    background-color: #ffffff;
    overflow-y: auto;
    max-height: 18rem;
    z-index: 2;
    box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
    transition:  0.15s ease-in-out;
}
.pos--dropdown-list > li {
    font-size: .8rem;
    text-transform: uppercase;
    font-weight: 600;
    padding: .5rem 1rem;
    color: black;
    cursor: pointer;
    line-height: 1;
    transition: opacity 0.2s linear;
}
.pos--dropdown-list > li:hover {
    background-color: #27ccab49;
    color: #27ccaa;
}
.pos--dropdown-list > li.selected {
    background-color: #27ccab;
    color: #ffffff;
}
.pos--dropdown-list::-webkit-scrollbar {
    background-color: transparent;
    width: 0.5rem;
}
.pos--dropdown-list::-webkit-scrollbar-track {
    background-color: transparent;
    width: 0.5rem;
}

  .pos--dropdown-list::-webkit-scrollbar-thumb{
      visibility: hidden;
      background-color:   rgba(20, 20, 20, 0.3);
      border-radius: 1rem;

    }
  .pos--dropdown-list:hover::-webkit-scrollbar-thumb,
  .pos--dropdown-list:active::-webkit-scrollbar-thumb,
  .pos--dropdown-list:focus::-webkit-scrollbar-thumb
   {
    background-color:  rgba(20, 20, 20, 0.3);
    border-radius: 1rem;
    visibility: visible;
   }

   .pos--dropdown-list-edit::-webkit-scrollbar {
    background-color: transparent;
    width: 0.5rem;
}
.pos--dropdown-list-edit::-webkit-scrollbar-track {
    background-color: transparent;
    width: 0.5rem;
}

  .pos--dropdown-list-edit::-webkit-scrollbar-thumb{
      visibility: hidden;
      background-color:  rgba(20, 20, 20, 0.3);
      border-radius: 1rem;
  }
  .pos--dropdown-list-edit:hover::-webkit-scrollbar-thumb,
  .pos--dropdown-list-edit:active::-webkit-scrollbar-thumb,
  .pos--dropdown-list-edit:focus::-webkit-scrollbar-thumb
   {
    background-color: rgba(20, 20, 20, 0.3);
    border-radius: 1rem;
    visibility: visible;
    
   }

.item_variation{
    margin-left: 5px;
    font-size: 0.68rem;
    color:#0a8d6e;
}
.item_property{
    color: #403e48;
    font-size: 0.65rem;
}
.item_property::after{
    content: " , ";
}
.item_property:last-child::after{
    content: ""
}

.pos--cart-actions-menu {
    display: none;
    top:-3px !important;
    background-color: #fff !important;
    right: -2px !important;
}
.pos--cart-item:hover .pos--cart-actions-menu {
    display: flex;
}

.itemQtyBtn{
    border: none;
    cursor: pointer;
    color: #ffffff;
    border-radius: 50%;
    padding: 0rem 0.25rem;
    padding-top: 0.12rem;
}

.cart-increment{
    background-color: #0a8d6e;
}

.cart-decrement{
    background-color: rgb(170, 170, 170) !important;
}

.simplebar-scroll-content {
    padding-right: 50px !important;
    /* margin-bottom: -50px !important; */
}
.itemModal{
    height:  calc(100vh - 17rem);
 }

 .switchIcon{
    font-weight: 600;
    color: white;
    font-size: 0.63rem;
    display: flex;
    justify-content: start;
    align-items: center;
    height: 100%;
    width: 60px;
 }
.switchUnchecked{
    margin-left: -27px;
    justify-content: end !important;
}

.bussines-day{
    font-size: 0.85rem;
}

@media only screen 
  and (min-device-width: 700px) 
  and (max-device-width: 1200px) 
  and (orientation: landscape) {
    html {
        font-size: 75% !important;
    }
    .fk-customer-screen{
        height: calc(100vh - 11.15rem) !important ;   
    }
    .customer-screen-wrap{
        height: calc(100vh - 425px) !important;
    }
    .customer-font {
        font-size: 2.3rem !important;
    }
    .customer-font-total{
        font-size: 3rem !important;
    }
}

@media only screen and (max-width: 1180px) { 
    html {
        font-size: 65%;
    }
    .customer-font {
        font-size: 2.2rem;
    }
    .customer-font-total{
        font-size: 2.5rem !important;
    }
    #Group_77{
        width: 85%;
    }
     #table-half{
        width: 85%;
    }
    .halfTable{
        width: 63%;
    }
    .total-bill {
        font-size: 0.9rem;
    }
    .switch-btn .react-switch-bg,.switch-btn2 .react-switch-bg{
        width: 60px !important;
    }
    .switch-btn .react-switch-handle{
        transform: translateX(39px) !important;
    }
    .switch-btn2 .react-switch-handle{
        transform: translateX(1px) !important;
    }
    .pos--left-inner-addon {
        max-height: 3.4rem;
        padding-bottom: .3rem;
    }
    .menu-left .simplebar-content{
        margin-right: -2.8rem !important;
    } 
    .dropdwn1--btn{
        border-right: 0.15rem solid #ffffff8e;
    }
    .res-table{
        height: calc(100vh - 20rem) ;
    }
    .offer-tip .tool-tip{
        top: 22px;
        position: absolute;
        left: 30%;
    } 
    .offer-tip .disable-tip{
    display: none !important;
    }
    
    .h-100 {
        height: 40vh !important;
    }
    .css-ueao73-control {
        max-width: 11rem !important;
        min-height: 15px !important;   
    }
    .search {
        margin-top: -2.3rem;
    }
    /* .halfTableLeft{
        right: 10%;
    }
    .halfTableRight {
        right: 51%;
    } */
    .css-1rsfomi-ValueContainer {
        padding: 2px 0 !important;
        margin: 2px !important;
    }
    .css-em0x53-singleValue {
        font-size: 1.1rem !important;
    }

    .bill-right .row {
        max-height: 23px !important;
    }
    .css-1aqs1t4-placeholder{
        font-size: 1rem !important;
    }
    /* Modal */
    .count{
        left: 60px !important;
        bottom: -9px;
        margin: 4px 0;
    }
    .plus{
        top: 20px;
        left: 122px;
    }
    .plus .fa-plus{
        font-size: 14px !important;
    }
    .minus{
        top: 22px;
        right: 110px;
    }.minus .fa-minus{
        font-size: 14px !important;
    }
    /* Navbar */
    .justify-content-lg-between{
        justify-content: end !important;
    }
    /* bill Container*/
    .css-1w2stv7-indicatorContainer svg{
        height: 10px;
        width: 10px;
        }
    .pos--left-inner-addon .form-control{
        font-size: 1.1rem;
    }
    .css-b62m3t-container{
        font-size: 1.4rem;
    }
    h6{
        font-size: 1rem !important;
    }

    .pos--category-nav-item {
        font-size: 1.1rem;
        padding: 0.5rem 0.9rem !important;
        margin: 0.2rem 0.5rem;
        margin-right: 0.1rem !important;
        border-radius: 0 !important;
        border: 0.1rem solid hsla(0,0%,100%,0) !important;
        border-bottom: 0.15rem solid #888888b2 !important;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }
    .pos--category-nav-item.active {
        /* background-color: #98e8c1; */
         color: #0a8d6e;
         border: 0.1rem solid #0a8d6e !important;
         font-weight: 500;
         border-radius: 0.5rem !important;
     }
     .pos--category-nav-item.active:hover{
        background-color: #0a8d6e;
        color: white;
    }
    .item-name{
        font-size: 1.25rem;
    }
    .item-img{
        height: 7.2rem;
    }
    .items-wrap {
        height: calc(100vh - 325px);        
    }
    .customer-screen-wrap{
        height: calc(100vh - 380px);
    }
    .itemModal{
        height:  calc(100vh - 17rem);
    }
}

@media only screen and (max-width: 1024px) { 
  html {
      font-size: 50%;
  }
  .offer-tip .tool-tip{
    top: 19px;
    position: absolute;
    left: 31%;
   
  }
  

  .offer-tip .disable-tip{
    display: none !important;
  }
  .h-100 {
    height: 50vh !important;
}

.mb-5 {
    padding-top: -20px;
}
.minus{
    top:13px
}
.plus{
    top:13px
}
.count{
    left: 66px !important;
}

.css-ueao73-control {
    max-width: 11rem !important;
    min-height: 27px !important;
}

.pos--left-inner-addon .form-control{
    font-size: 1.2rem;
}

.css-13y47wk-control {
    min-height: 30px !important;
}
.items-wrap {
    height: calc(100vh - 300px);
}
.customer-screen-wrap{
    height: calc(100vh - 320px);
}

.itemModal{
   height:  calc(100vh - 17rem);
}

/* .css-ueao73-control {
    max-width: 11rem !important;
    
}
.css-1rsfomi-ValueContainer {
    padding: 2px 0 !important;
    margin: 2px !important;
}
.css-em0x53-singleValue {
    font-size: 1.1rem !important;
}

.bill-right .row {
    max-height: 20px !important;
}
.css-1aqs1t4-placeholder{
    font-size: 1rem !important;
} */



/* .modal {
    position: fixed;
    height: 600px
} */

/* .css-ueao73-control {
    min-height: 10px;
} */


}

  
@media only screen and (max-width: 992px){

    .minus{
        top:11px;
        right: 59px;
    }
    .plus{
        top:11px;
        left: 70px;
    }
    .count{
        left: 38px !important;
    }

}


    /* .pos--main-container {
        height: 100% !important;
        max-height: calc(1000vh - 110px) !important;
        width: 100% !important;
        max-width: calc(1000vh - 110px) !important;

    }
   
    .pos--options-container {
           flex-direction: row;
           

    }
    .bg-success{
        margin: 0.5rem !important;
    }

    .new-btn{
    font-size: x-large;
    }

    .pos--btn {
        padding: 4px 8px !important;
        border-radius: 0.5rem !important;
        font-weight: 200 !important;
        padding: 2px;
        text-transform: uppercase;
        font-size: 0.8rem;
        background-color: #27ccaa !important;
    }
    
    
    /* Category Styles*/
    /* .pos--category-nav-item{
        font-size: 0.8rem;
        padding: 15px 8px !important; 
    }
    .pos--category-nav-item.active{
        padding: 15px 8px !important; 
        font-weight: 500;
    }
    .css-ueao73-control{
        
        padding: 0;
    }
    
    .css-13yqu4e-menu{
        margin-left: 24px !important;
    }
    */
    /* Menu Item styles */
    /* .pos--menu-item{
        max-height: 14rem !important;
    }
    .pos--menu-item figcaption{
        margin-bottom: 0px !important;
        font-size: 13px;
    } */

    /* Bill Container Styles*/
    /* .pos--bill-container{
        max-height: 580px !important;
    }
    .cart-1 h6{
        font-weight: 500 !important;
        font-size: 1rem !important;
    }
    
    .bill-total{
        font-weight: 500 !important;
        font-size: 1rem !important;
    }
    
    .print-btn{
        padding: 0px !important;
        margin-left: 1.2rem;
        width: 70px !important;
    }
    .print-btn button{
        padding: 4px 4px !important;
        height: 1.6rem !important;
        line-height: 0.rem !important;
        
        
    }
    .cancel-btn{
        padding: 0px !important;
        width: 60px !important;
        margin-left: 6rem !important;
        margin-top: -26px !important;
    }
    .cancel-btn button{
        padding: 4px 4px !important;
        height: 1.6rem !important;
        
    }
    .print-cancel-row{
        width: 20rem !important;
        margin: 5px 0 !important;
    }
    .bill-submit{
        width: 170px;
        justify-content: center !important;
    }
    .bill-submit button{
        width: 50px;
        padding: 4px 4px !important;
    }
    .bill-last-row{
        margin-left: 8px !important;
        margin-top: 15px;
    } */
    /* Main-wrapper*/
    /* .bill-right{
        padding: 0 !important;
        padding-right: 8px !important;
    }
    .menu-left{
        padding: 0 !important;
    } */
    /* Footer */
    /* #footer{
        width: 100% !important;
        margin-bottom: 0px !important;
        margin-top: -30px !important;
    } */
    /* #footer .row{
        flex-direction: row !important;
    } */
    
    /*  Modal Styling */
    /* .modal-left-col{
        width: 100px !important;
    } */
    /* .modal-right-col{
        width: 390px !important;
    } */
    /* .modal-list-item{
        width: 100%;
        padding: 8px 4px !important;
    } */
    
    /* .modal-btn{
        width: 160px;
    } */
    /* .modal-btn-2{
        width: 160px;
        margin-left: 6px !important;
    } */
    /* .modal-text-area{
        width: 300px !important;
    } */ 










/* .pos--table--body-top  {
    width: 100%;
    display: flex;
    flex-direction: row;
    border: 1px solid black;
} */
/* .pos--table--top {
    flex-basis: 50%;
    width: 50%;
}
.pos--table > div > *:last-child {
    border-left: 1px solid black;
}
.pos--table > .border-0 > * {
    border-left-width: 0px;
} */