html {
  --primary: #0a8d6e !important;
}

/* body {
  overflow: hidden;
} */

/* for less table data */
.min-table-height {
  min-height: 130px;
}

/* for clock font size */
.font-10px {
  font-size: 10px;
}

/* for toast notification */
.toast-notification {
  text-align: center;
}

/* active page in pagination */
.pagination-active {
  background-color: var(--primary);
  color: #fff;
}
/* no pagination style */
.no-pagination-style {
  height: 33px;
}


/* table data loading */
.loadingBlur{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff30;
  backdrop-filter: blur(1px); 
  z-index: 1040;
}
.loading {
  width: 60px;
  height: 60px;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  position: absolute;
  border-radius: 50%;
  border: 3px solid var(--primary);
  border-top: 3px solid transparent;
  animation: loading-animation 1s infinite linear;
  -webkit-animation: laoding-animation 1s infinite linear;
  -moz-animation: laoding-animation 1s infinite linear;
  z-index: 1040;
}

/* custom bell animation */
.bell, .bell-border, .btn-bell {

  border-radius: 50%;
  position: absolute;
  top: 19px;
  z-index: 1047;
  left: 50%;
  transform: translate(-50%, -50%);

}

.bell {

  height: 40px; 
  width: 40px;
  margin-left: -22px;
  box-shadow: -1px 2px 10px #999;
  background: #fa172e;
  transform: translate(-50%, -50%);
  animation-name: col;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  cursor: pointer;

}

.bell-border {
  height: 40px; 
  width: 40px;
  animation-name: bord-pop;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  box-shadow: 2px 2px 5px #ccc, -2px -2px 5px #ccc;
}

.bell-border-primary {
  border: 1px solid #0a8d6e !important;
}

.bell-border-danger {
  border: 1px solid #fa172e !important;
}

.btn-bell {

  color: white;
  font-size: 16px;
  animation-name: bell-ring;
  animation-duration: 2s;
  animation-iteration-count: infinite;

}

@keyframes bord-pop {

  0% {

      transform: translate(-50%, -50%);

  }

  50% {

      transform: translate(-50%, -50%) scale(1.9);

      opacity: 0.1;

  }

  100% {

      transform: translate(-50%, -50%) scale(1.9);

      opacity: 0;

  }

}

@keyframes col {

  0% {

      transform: scale(1) translate(0,0);

  }

  10% {

      transform: scale(1.1) translate(0,0);

  }

  75% {

      transform: scale(1) translate(0,0);

  }

  100% {

      transform: scale(1) translate(0,0);

  }

}

@keyframes bell-ring {

  0% {

      transform: translate(-50%, -50%);

  }

  5%, 15% {

      transform: translate(-50%, -50%) rotate(25deg);

  }

  10%, 20% {

      transform: translate(-50%, -50%) rotate(-25deg);

  }

  25%  {

      transform: translate(-50%, -50%) rotate(0deg);

  }

  100% {

      transform: translate(-50%, -50%) rotate(0deg);

  }

}

/* CustomTabs.css */
.custom-tabs .nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  border: none !important;
  color: #707070 !important;
  font-size: 14px !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.custom-tabs .nav-link .tab-icon {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 14px;
}

.custom-tabs .nav-link.active {
  background-color: #f2f2f2 !important;
  color: var(--primary) !important;
  border-bottom: 2px solid var(--primary) !important;
  border-radius: 4px 4px 0 0 !important; 
}

.custom-tabs .nav-link:not(.active):hover {
  background-color: #f9f9f9 !important;
}


@keyframes laoding-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rdtPicker{
  /* top: -9.8rem */
}

.lds-roller {
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 1000;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7.3px;
  height: 7.3px;
  border-radius: 50%;
  background-color: var(--primary);
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
  width: 3px;
  height: 3px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
  width: 3.5px;
  height: 3.5px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
  width: 4px;
  height: 4px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
  width: 5px;
  height: 5px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
  width: 6px;
  height: 6px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
  width: 6.5px;
  height: 6.5px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* background of language */
.fk-language__flag {
  background-size: cover;
}

.btn-light-danger{
    color: #f5364a;
    background-color: #fde0e3;
    border-color: #fde0e3;
}
.btn-light-danger:hover{
  color: #fff;
    background-color: #f64e60;
    border-color: #f64e60;
}
/* hide pagination */
@media screen and (min-width: 1200px) {
  .table-bottom-info-hide {
    height: calc(100vh - 120px);
  }
  
}

@media screen and (max-width: 1180px) {
  .customer-items-wrap {
    height: calc(100vh - 270px);
    overflow: auto;
  }
  .customer_video{
    height: calc(100vh - 134px) !important;
  }
}
@media screen and (max-width: 1024px) {
  .customer-items-wrap {
    height: calc(100vh - 265px);
    overflow: auto;
  }
  .customer_video{
    height: calc(100vh - 130px) !important;
  }
}
@media screen and (max-width: 992px) {
  .fk-settle-cal-loyality {
    height: calc(100vh - 320px) !important;
  }
  .fk-settle-cal{
    height: calc(100vh - 230px) !important;
  }
}


/* image in table td */
.table-img-large {
  width: 60px;
  height: 60px;
  background-size: cover;
  border-radius: 60px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/assets/img/waiter.jpg");
}

.table-image{
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  object-fit: cover;
}

/* manage page border-right show */
.simplebar-content {
  margin-right: -12px !important;
}

/* translation-input */
.translation-input {
  max-width: 150px;
}

/*  custom-bg-secondary */
.custom-bg-secondary {
  background-color: #ebeef6;
}

/* variation-min-price-input-width */
.variation-min-price-input-width {
  min-width: 90px;
}

/* text-decoration: line-through; */
.text-deleted {
  text-decoration: line-through;
}

/*Logo background on load*/
.fk-brand__img--fk {
  background-image: none;
}

/*  text-green for workperiod */
.text-green {
  color: #28a745;
}

/* Calculator css override */
.calculator {
  width: 350px;
}

/* payment type multiple clear all button hide */
.payment-type-parent .select__indicator.select__clear-indicator {
  display: none !important;
}
.payment-type-parent .select__multi-value__remove {
  display: none !important;
}
.payment-type-parent .select__multi-value {
  min-width: 60px !important;
  text-align: center !important;
}
.payment-type-parent .select__multi-value__label {
  padding-right: 6px !important;
  padding-left: 6px !important;
}

/* Pos page group name shaddow remove */
.btn-outline-danger:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

/* reverse the ordre list items */
.reverse-this {
  display: flex;
  flex-direction: column-reverse;
}

/* for remove button from order list in POS */
.make-this-relative {
  position: relative;
  top: 26px;
  left: 0;
  margin-top: -31px;
  margin-left: 48%;
  cursor: pointer;
  max-width: 51.88px;
}

.make-this-relative-mobile {
  position: relative;
  top: -53px;
  left: 25px;
  /* margin-top: -31px; */
  margin-left: 25%;
  cursor: pointer;
  max-width: 51.88px;
}

/*  pointer-cursor */
.pointer-cursor {
  cursor: pointer;
}

/*  change-background */
.change-background {
  background-color: #0a8d6e !important;
}

/* new-customer-pos */
.new-customer-pos {
  font-weight: 450 !important;
  font-size: 12px !important;
}

/* pos page work period css */
.fk-left-over {
  position: relative;
}
.fk-left-overlay {
  border-radius: 4px;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  background: #f64e5fa9;
  z-index: 3;
}
.fk-overlay{
  border-radius: 4px;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  background: #0a8d6e8c;
  z-index: 3;

}

.user_branch_name{
  font-size: 1rem;
  color: #27ccaa !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 600;
  margin: 2px 5px 8px 4px !important;
}
.user_branch{
  color: #374a5e;
  font-size: 10px;
  margin: 1px 5px -4px 4px !important;

}
@media screen and (min-width: 768px) {
  .fk-left-overlay {
    position: absolute;
  }
}
.fk-left-overlay__content {
  min-width: 400px;
  background-color: #ffffffef;
  padding: 20px;
  border-radius: 4px;
}

/* checkbox align item center cancel */
.mx-checkbox {
  -webkit-box-align: flex-start !important;
  align-items: flex-start !important;
}
.mx-checkbox__input-sm {
  margin-top: 12px !important;
}
.mt-0-kitchen {
  margin-top: 0px !important;
}

.mt-1-kitchen {
  margin-top: 1px !important;
}

/* POS Page Tab Screen Order List */
li.addons-list__item.active {
  background: #de222a;
  color: white !important;
  border-radius: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
}

/* profile update */
.profile-update-full {
  height: calc(100vh - 174px) !important;
}

.no-order {
  height: calc(100vh - 230px);
}

.main-height-100 {
  height: calc(100vh - 20px) !important;
}

.installation-full-page {
  height: calc(100vh - 30px) !important;
}

.login-page-background {
  background-image: url("/assets/img/logo.png") !important;
}

/* safari pos issue */
.fk-dish-card__img {
  align-items: center;
}

.congratulation-page {
  height: calc(100vh - 30px) !important;
  display: flex;
  width: 100%;
}

.congratulation-page-margin {
  margin: auto;
}

/* Updates of settle */

.myBorderPrintBottom {
  border-bottom: 1px solid black;
}

.myBorderDashboardBottom {
  border-bottom: 1px solid #121053;
}

.myBorder {
  border-top: 1px dotted black;
  border-bottom: 1px dotted black;
}

.myBorderTop {
  margin-top: 10px;
  border-top: 1px dotted black;
}
.myBorderBottom {
  border-bottom: 1px dotted black;
}

.myBorderTopCustomer {
  border-top: 1px dotted black;
}

/* SETTLE STYLE */
.fk-settle-container {
  height: calc(100vh - 100px);
}
.fk-settle {
  background: #fff;
  height: calc(100vh - 130px);
}
.fk-settle__products {
  height: calc(100vh - 198px);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.fk-settle__products .simplebar-content {
  overflow-x: hidden;
}
.fk-settle-group__label {
  display: inline-block;
}
.fk-settle-group__input {
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.fk-settle-group__input:focus , .fk-settle-group__input:active {
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
}

.toggle-option {
  text-align: center;
  font-weight: bold;
  width: 5.5rem;
  background-color: #f0f0f0;
  color: rgb(122, 122, 122);
  transition: all 0.3s ease;
}

.toggle-option.active {
  background-color: #0a8d6ef6;
  color: white;
}

.fk-settle-cal-loyality {
  height: calc(100vh - 338px);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.fk-settle-cal {
  height: calc(100vh - 248px);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.fk-settle-cal-btn {
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 1.5rem;
  transition: all 0.3s ease;
}
@media screen and (min-width: 768px) {
  .fk-settle-cal-btn {
    font-size: 2.3rem;
  }
}
@media screen and (min-width: 992px) {
  .fk-settle-cal-btn {
    font-size: 2.2rem;
  }
}
@media screen and (min-width: 1200px) {
  .fk-settle-cal-btn {
    font-size: 1.5rem;
  }
}

.t-bg-w {
  background: #fff;
}
.t-bg-p {
  background: #3ad38d;
}
.t-bg-p:hover {
  background: #2bc580;
}

.t-bg-d {
  background: #23282c;
}
.t-bg-ac {
  /* background: #0073AA; */
  background: #23282c77;
}
.t-bg-r {
  background: #fe574e;
}
.fk-settle__pay {
  height: calc(100vh - 160px);
}

/* Pos page dept tag */

.button {
  display: inline-block;
  font-family: "Montserrat", "Trebuchet MS", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  position: relative;
  padding: 0.8em 1.4em;
  padding-right: 4.7em;
  background: #009ed8;
  border: none;
  color: white;
  transition: 0.2s;
}
.button:before,
.button:after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding-top: inherit;
  padding-bottom: inherit;
  width: 2.8em;
  content: "\00a0";
  font-family: "FontAwesome", sans-serif;
  font-size: 1.2em;
  text-align: center;
  transition: 0.2s;
  transform-origin: 50% 60%;
}
.button:before {
  background: rgba(0, 0, 0, 0.1);
}
.button:hover {
  background: #0079a5;
}
.button:active,
.button:focus {
  background: #002e3f;
  outline: none;
}
.button {
  min-width: 100%;
}
.arrow {
  background: #09c2de;
}
.arrow:hover {
  background: #09c2de;
}
.arrow:active,
.arrow:focus {
  background: #0ac090;
}
.button.arrow.active {
  background: #0ac090;
}
.arrow:after {
  content: "\F054";
}
.arrow:hover:after {
  -webkit-animation: bounceright 0.3s alternate ease infinite;
  animation: bounceright 0.3s alternate ease infinite;
}
/* active crust */
.activeCrust {
  background: #0ac090;
}

.activeCrust:hover {
  background: #0ac090;
}
.addonPadding5{
 padding-top: 2px;
}
.addonPadding4{
 padding-top: 2px;
}

.addonPadding48 {
  padding-top: 35px;
}

.addonPadding35 {
  padding-top: 26px;
}

.addonPadding24 {
  padding-top: 0px;
}

.akash-table-for-print-padding.table > :not(caption) > * > * {
  padding: 0.2rem 0.5rem;
}

/* website */
.custom-nav .nav-link {
  display: block;
  padding: 0.5rem 0.9rem !important;
}

.langSelect {
  background: transparent;
  border: 1px solid #e6e6e6;
  color: #2a435d;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
}
/* website variation drawer */
.kh-drawer__body_cart {
  height: calc(100vh - 200px);
  background: #ffffff;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
  position: relative;
  height: 300px;
}

/* custom button */
.btn-gradient-mini {
  padding: 4px 12px;
  font-size: 12px;
  margin: 5px;
  border: none !important;
}

/* Saas profile card */
.saas_card_profile {
  border-radius: 20px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 15px 39.2px 0.8px rgba(17, 0, 92, 0.1);
  padding-top: 65px;
  padding-bottom: 65px;
  position: relative;
  margin-right: 20px;
}
.upper_bg_shape {
  height: 150px;
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: #ffd9cf;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.saas_user_img {
  border-radius: 50%;
  outline: 1px solid #f33d3d;
  position: relative;
  z-index: 22;
}
.avatar_img_wrapper {
  margin-left: 35px;
  margin-bottom: 30px;
}

.saas_card_profile .user_title {
  background: #eff3ff;
  border-left: 2px solid #f33d3d;
padding: 20px;
  width: 95%;
  margin: 0 auto;
  display: block;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 35px;
}
.saas_card_profile .user_title .icon {
  font-weight: 400;
  margin-right: 15px;
}

.user_info_wrapper {
padding-left: 40px;
margin-right: 40px;
}
.user_info_wrapper .user_icon{
  width: 50px;
  height: 50px;
  border-radius: 16px;
  text-align: center;
  line-height: 50px;
  color: #002e3f;
  display: inline-block;
  border: 1px solid #002e3f;
  margin-right: 15px;
}

.user_info_wrapper .list_wrapper {
  font-size: 18px;
  display: flex;
  font-weight: 700;
  margin-bottom: 30px;
}

.user_info_wrapper .title_wrapper .title {
  font-size: 18px;
  display: block;
  font-weight: 700;
}
.user_info_wrapper .title_wrapper .subtitle {
  font-size: 14px;
  display: block;
  font-weight: 400;
}

.wrapper-margin-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

/* subs title  */
.saas_subs_title {
  font-weight: 500;
  text-transform: capitalize;
}
.saas_subs_title .saas_subs_icon{
  background-color: #f33d3d;
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius:50%;
  line-height: 30px;
  display: inline-block;
  text-align: center;
  margin-right: 20px;
  }

.saas_subs_title .color-red{
  color: #f33d3d;
  font-weight: 700;
}
/* single_saas_info_card*/
.single_saas_info_card {
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 20px 0px rgba(99, 99, 99, 0.1);
    padding: 10px;
}
.single_saas_info_card .saas_info_img{
    max-width: 100px;
    max-height: 70px;
    margin-bottom: 10px;
    display: flex;
   margin-right: auto;
   margin-left: auto;

}
.single_saas_info_card .count_number{
    font-size: 36px;
    font-weight: 900;
    display: block;
    text-align: center;
}
.single_saas_info_card .title{
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    display: block;
    text-align: center;
}

.custom-2-field .rdt{
  flex: 0 0 auto;
  width: 50%;
}

/* saas_profile_history_wrapper */
.saas_history_form {
  border: 1px solid #ffeee9;
}
.saas_profile_history_wrapper {
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 20px 0px rgba(99, 99, 99, 0.1);
    padding: 30px;
    height: 633px;
    overflow-y: scroll;
}

.saas_payment_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.saas_payment_header .title {
  font-size: 18px;
  font-weight: bold;
  text-transform: capitalize;
}
/* saas_history_table */
.saas_history_head  {
background: #ffeee9;
}

.saas_history_table_row {
  border-bottom: 1px solid #ffeee9;
}
.saas_history_table_body {
  border: 1px solid #ffeee9;
}

/* loading  style*/
.saas_card_loading {
  width: 30px;
  margin: 0 auto;
}

.history_rolling {
  margin-top: 100px;
  margin-left: 255%;
}
.payment-btn {
  display: inline-block;
  padding: 10px!important;
  border-radius: 5px!important;
  width: initial;
  font-size: 12px!important;
  font-weight: 500;
  border: none;
  margin-top: 12px;
}
.mt-0{
  margin-top: 0 !important;
}

.simplebar-content .dropdown{
  margin-left: 12px;
}
.w-38{
  width: 38%;
}
.partyname{
  float: left;
  font-size: 11px;
  line-height: 21px;
}
.span-dropdown{
  cursor: pointer;
}
.re-print-btn{
  background-color: #5b5e80 !important;
  color: #fff !important;
  font-size: 0.45rem;
  padding: 0.75rem 0.1rem 0.55rem 0.1rem;
  border: 0;
}
.showing {
  display: block;
  opacity: 1 !important;
  background: rgb(0 0 0 / 32%);
}
.close-1 {
  font-size: 1.5rem;
  margin-top: -0.5rem;
}
.modal.fade.showing .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, 100px);
}
/* pos locked stuff */
.offers-locked-overlay {
  right: calc(var(--bs-gutter-x) / 2);
  position: absolute;
  top: -22px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a1a1a1;
  font-size: 1.2rem;
}

.reports-dashboard .react-datepicker-wrapper,
.reports-dashboard .react-datepicker-wrapper > .react-datepicker__input-container,
.reports-dashboard .react-datepicker__input-container > input
{
  height: 100%;
}

/* For React Print */
/* @media all {
  .page-break {
    display: none;
  }
}



@page {
  size: auto;
  margin: 20mm 0;
  padding-top:20mm;
} */

@media print {
  .report table { page-break-inside:auto }
  .report tr    { page-break-inside:avoid; page-break-after:auto }
  .report thead { display:table-header-group }
  .report tfoot { display:table-footer-group }
}

.bank-table-input{
  min-width: 180px;
}
.btn-add-icon{
  color: #0a8d6e;
}

.add-bank-table{
  height: 26rem !important ; 
}
.add-bank-table-pad-last{
  height: 25rem ;
}
.add-bank-table-pad-secLast{
  padding-bottom: 4.5rem;
}
.add-bank-table-pad-thrLast{
  padding-bottom: 4.5rem;
}
.add-bank-table-height{
  height: 18rem ;
}
.react-datetime-range-picker {
  font-size: 10px !important; /* Change this to your desired font size */
}
.hov-btn{
  background-color: #e5e7e7;
}

.new-text-style{
  font-size: 1rem;
  font-weight: 400;
}

.edit-icon{
  cursor: pointer;
  font-size: 0.9rem;
  color:white;
  line-height:0.8rem;
}
.del-btn{
  height:1.7rem;
  width:1.7rem;
  padding:0rem 0rem 0.1rem 0rem;
}
/* .del-icon{
  cursor: pointer;
  color:white;
  margin-right:0.5rem;
} */

/* Style for inactive tabs */
.nav-tabs .nav-link {
  color: #333;
  background-color: #eee;
  border: 1px solid #ccc;
  margin-right: 10px;
  font-weight: bold;
  font-size: 12px;
}

/* Style for active tab */
.nav-tabs .nav-link.active {
  color: #fff;
  background-color: #0a8d6e;
  border: 1px solid #0a8d6e;
  margin-right: 10px;
  font-weight: bold;
  font-size: 12px;
}

.table-scroll{
  margin-top: 10px;
  max-height: 372px;
  overflow-y: auto;
}

.table-scroll::-webkit-scrollbar {
  background-color: transparent;
  width: 0.5rem;
}
.table-scroll::-webkit-scrollbar-track {
  background-color: transparent;
  width: 0.5rem;
}

.table-scroll::-webkit-scrollbar-thumb{
    visibility: hidden;
    background-color:   rgba(20, 20, 20, 0.3);
    border-radius: 1rem;

  }
.table-scroll:hover::-webkit-scrollbar-thumb,
.table-scroll:active::-webkit-scrollbar-thumb,
.table-scroll:focus::-webkit-scrollbar-thumb
 {
  background-color:  rgba(20, 20, 20, 0.3);
  border-radius: 1rem;
  visibility: visible;
 }

.cus-pad td{
  padding: 0.1rem 0.5rem !important;
}

.cus-pad th{
  padding: 0.1rem 0.5rem !important;
  font-weight: 500;
}

.cus-pad input{
  width: 70px;
  border: 1px solid #ced4da;
  border-radius: 3px;
}

.modal.fade.showing .modal-dialog-typeItem {
  transition: transform 0.3s ease-out;
  transform: translate(0, 60px);
}

.customer-border-radius{
  border-radius: 1.3rem 1.3rem 0 0;
}

.customer-border{
  border-top: 1.5px solid #d1d0d0;
}
.customer-items-wrap {
  height: calc(100vh - 430px);
  overflow-y: scroll;
  scrollbar-width: none;
 -ms-overflow-style: none;
 transition: opacity 0.2s linear;
}
.customer-items-wrap::-webkit-scrollbar{
  background-color: transparent;
  width: 0.4rem;
}
.customer-items-wrap::-webkit-scrollbar-track {
  background-color: transparent;

}

.customer-items-wrap::-webkit-scrollbar-thumb{
    visibility: hidden;
    background-color:  rgba(20, 20, 20, 0.3);
    border-radius: 1rem;
}
.customer-items-wrap:hover::-webkit-scrollbar-thumb,
.customer-items-wrap:active::-webkit-scrollbar-thumb,
.customer-items-wrap:focus::-webkit-scrollbar-thumb
 {
  background-color:  rgba(20, 20, 20, 0.3);
  border-radius: 1rem;
  visibility: visible;
 }

.wrapped-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.customer_video{
  height: calc(100vh - 128px);
  width: 100%;
  object-fit: cover;
}

.stock-notify-dropdown{
  position: relative;
}

.stock-notify-dropdown-menu{
  max-height: 300px;
  overflow-y: auto !important;
  min-width:200px;
  background-color: #ebeef6;
  padding: 5px;
}

.stock-notify-dropdown-item{
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  background-color: white;
  border-radius: 5px;
  border-left:5px solid red;   
  padding:2px;
  margin-bottom:5px;
}

.stock-items-red{
  border-radius: 5px;
  border-left:6px solid red; 
}

.stock-items-green{
  border-radius: 5px;
  border-left:6px solid green; 
}

.stock-notify-dropdown-list{
  width:100%;
  padding:0px;
  cursor: default;
}

.stock-notify-dropdown-list.disabled, .stock-notify-dropdown-list:disabled {
  color: #000;
  /* pointer-events: none; */
  /* background-color: transparent; */
  opacity: 1;
}

.stock-notify-badge{
  position: absolute;
  left: 75%;
  top: -5px;
  font-size: 12px;
}

.custom-toggle{
  padding:4px;
  border-radius:10px;
  width:32px;
  height:32px;
}

.custom-toggle::after{
  display: none !important; 
}

.bell-icon{
  animation: mymove 0.5s infinite;
}

@keyframes mymove {
  0% { transform: rotate(0deg); }
  15% { transform: rotate(10deg); }
  25% { transform: rotate(15deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(-15deg); }
  85% { transform: rotate(-10deg); }
  100% { transform: rotate(0deg); }
}

.badge{
  background-color: orange;
}

.stock-notify-dropdown-menu::-webkit-scrollbar {
  background-color: white;
  width: 0.5rem;
}
.stock-notify-dropdown-menu::-webkit-scrollbar-track {
  background-color: #ebeef6;
  width: 0.5rem;
}

.stock-notify-dropdown-menu::-webkit-scrollbar-thumb{
    visibility: hidden;
    background-color:   rgba(20, 20, 20, 0.3);
    border-radius: 1rem;

  }
.stock-notify-dropdown-menu:hover::-webkit-scrollbar-thumb,
.stock-notify-dropdown-menu:active::-webkit-scrollbar-thumb,
.stock-notify-dropdown-menu:focus::-webkit-scrollbar-thumb
 {
  background-color:  rgba(20, 20, 20, 0.3);
  border-radius: 1rem;
  visibility: visible;
 }