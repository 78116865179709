.dashboard__card-container {
  row-gap: 1rem;
}

.dashboard__card_view {
  height: 100%;
  background: #f8f8f8;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  padding: 0.75rem 0;
  border-radius: .75rem;
  /* width: 16%;
  flex-basis: 16%; */
}
.adminDashboard__card{
  background-color: white;
}
.btn_load_radial{
  height: 300px;
  padding-top: 120px !important;
}
.btn_load_height{
  height: 14.5rem !important;
  padding-top: 5.4rem !important;
}
.btn_table_height{
  height: 12.5rem !important;
  padding-top: 4rem !important;
}
.btn_load{
  height: 270px;
  width: 100%;
  margin: auto;
  padding-top: 110px;
  text-align: center;
  background: #f8f8f8;
}
.ldr-height{
  top:40%;
  left:48%
}
.dashboard__card_list {
  background: #f8f8f8;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  padding: 1rem;
  border-radius: .75rem;
}

.dashboard__card {
  background: #f8f8f8;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  padding: 1rem;
  border-radius: .75rem;
  width: 19%;
  flex-basis: 19%;
}

.apexcharts-tooltip-text-y-label{
  display: none !important;
}

.donut-text{
  font-size: 1.1rem !important;
  font-weight: 600 !important;

}

.dashboard_percent{
  position: relative;
  cursor: pointer;
}
.dashboard_percent > span{
  position: absolute;
  display: none;
  background-color: #0a8d6e;
  color: #fff;
  padding: 0.7rem;
  border-radius: 0.5rem;
  z-index: 99;
  cursor: pointer;
 
}
.dashboard_percent > span::after{
  content: "";
  position: absolute;
  top: -5px;
  left: 35%;
  margin-left: -5px;
  border-top: 0 ;
  border-left: 5px solid #0a8d6e00;
  border-right: 5px solid #0a8d6e00;
  border-bottom: 5px solid #0a8d6e;
}

.dashboard_percent:hover > span{
  display: block;
}

.dashboard_table_icon{
  height: 1.3rem;
  fill: #ffffff;
  margin-top: -0.35rem;
  margin-left: 0.15rem;
  margin-right: 0.3rem;
}

.dashboard_card_up{
  fill: #0a8d6e;
  margin-top: -0.2rem;
  font-size: 1.2rem;
}

.dashboard_table_sort{
  fill: #ffffff;
  margin:0 0.2rem;
  font-size: 1.2rem;
}

.dashboard_card_down{
  fill: rgb(223, 5, 5);
  margin-top: -0.2rem;
  font-size: 1.2rem;
}

.flex-basis-100 {
  flex-basis: 100%;
}

.radialChart .apexcharts-legend-marker {
  border-radius: 3px !important;
}

.bar-widget__tooltip {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: .25rem 1rem;
  border-radius: .75rem;
}
.bar-widget__tooltip-primary {
  color: #0a8d6e;
  font-weight: 700;
  margin-bottom: 0;
}
.bar-widget__tooltip-secondary {
  font-size: 0.7rem;
  margin-bottom: 0;
}
.dashboard_text_secondary{
  color: #625f5fd4;
  font-weight: 500;
}
.list-heading{
  font-size: 1.23rem;
  font-family: "Roboto", sans-serif;
}
.list-head-text{
  font-size: 0.95rem;
  font-weight: 500 !important;
}
.list-text{
  font-size: 0.85rem;
  font-weight: 400 !important;
}

.production-table-view{
  max-height: 24rem;
}

.list-table-view{
  max-height: 14.5rem;
  padding-right: 0 3px;
  overflow-x: hidden;
}
.list-table-view .simplebar-content{
  margin-right: 0px !important;
}

.widget_border{
  border: 1px solid #a3a4a563;
}
.production_table_head{
  background-color: #0a8d6e;
  color: white;
}

.dashboard_table_head {
 padding: 0.1rem !important;
 font-size: 0.75rem !important;
 font-weight: 500;
}
.dashboard_table_body {
  padding: 0 !important;
  font-size: 0.7rem !important;
}

.tablecolor1{
  background-color: #ccf1eb !important;
}
.tablecolor2{
  background-color: #93e5d4 !important;
}
.tablecolor3{
  background-color: #0dbabac9 !important;
}
.tablecolor4{
  background-color: #23b799 !important;
}
.tablecolor5{
  background-color: #fd9292 !important;
}
.tableColorDef{
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 0.1rem;
}

@media screen and (max-width: 1180px){
  .ldr-height{
    top:25%;
  }
}

@media screen and (max-width: 992px){
  .list-table-view{
    max-height: 14.9rem;
  }
  .ldr-height{
    top:22%;
  }
}
